import React from "react";
import styled from "styled-components";

// Components
import { TagImages } from "./tag-images";

const Tag = styled.li`
  width: fit-content;

  & h2 {
    transition: 150ms color ease;

    &:hover {
      color: #3b9c62;
    }
  }

  & span {
    opacity: 0;

    &:first-of-type {
      margin-left: -7px;
    }
  }

  &.active,
  &:hover {
    & span {
      opacity: 1;
    }
  }

  & .content {
    display: ${props => props.display};

    & p {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.05em;
    }

    & a {
      transition: 150ms color ease;

      &:hover {
        color: #3b9c62;
      }
    }

    & .projects-list {
      margin: 1em 0;

      & p {
        margin: 0;
      }
    }
  }

  @media (max-width: 768px) {
    & .index-title h2 {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const IndexTags = ({
  tag,
  index,
  activeTag,
  setActiveTag,
  activeTagName,
  setActiveTagName,
  activeTagProjects,
  activeColumn,
  setActiveColumn,
  context,
}) => {
  const tagImages =
    activeTag === tag.node.id
      ? tag.node.data.images.length >= 1
        ? tag.node.data.images
            .filter(image => image.image.fluid !== null)
            .map((image, imageIndex) => (
              <img
                className={`active-image-${imageIndex + 1}`}
                key={`image_${tag.node.id}_${imageIndex}`}
                srcSet={image.image.fluid.srcSetWebp}
                src={image.image.fluid.srcWebp}
                alt={image.image.alt}
                loading="lazy"
                onClick={() => {
                  setActiveTag(null);
                  setActiveTagName(null);
                }}
              />
            ))
        : null
      : null;

  return (
    <>
      {activeTag === tag.node.id && tagImages !== null && (
        <TagImages
          images={tagImages}
          activeColumn={activeColumn}
          context={context}
        />
      )}
      <Tag
        key={`tag_${index}`}
        onClick={
          activeTag === tag.node.id
            ? () => {
                setActiveTag(null);
                setActiveTagName(null);
              }
            : () => {
                setActiveTag(tag.node.id);
                setActiveTagName(tag.node.data.title.text);
                setActiveColumn(activeColumn);
              }
        }
        display={activeTag === tag.node.id ? `block` : `none`}
        className={activeTag === tag.node.id ? `active` : ``}
      >
        <div className="index-title">
          <h2>
            <span>(</span>
            {tag.node.data.title.text}
            <span>)</span>
          </h2>
        </div>

        <div className="content">
          <div
            dangerouslySetInnerHTML={{
              __html: tag.node.data.text.html,
            }}
          />
          <div className="projects-list">{activeTagProjects}</div>
        </div>
      </Tag>
    </>
  );
};
