import React, { useState } from "react";
import styled from "styled-components";

// Components
import { IndexTags } from "../index/index-tags";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
`;

const Container = styled.ol``;

export const MobileTags = ({
  allTagsArray,
  activeTag,
  setActiveTag,
  activeTagName,
  setActiveTagName,
  activeTagProjects,
  setActiveTagProjects,
}) => {
  const [activeColumn, setActiveColumn] = useState(1);

  const leftArray = allTagsArray.map((tag, index) => (
    <IndexTags
      tag={tag}
      index={index}
      activeTag={activeTag}
      setActiveTag={setActiveTag}
      activeTagName={activeTagName}
      setActiveTagName={setActiveTagName}
      activeTagProjects={activeTagProjects}
      setActiveColumn={setActiveColumn}
      activeColumn={1}
      context={`mobile`}
      key={`single_mobile_array_0_tag_${index}`}
    />
  ));

  return (
    <Grid>
      <Container>{leftArray}</Container>
    </Grid>
  );
};
