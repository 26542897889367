import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
// import { createBreakpoint } from "react-use";

// Hooks

import { useMediaQuery } from "../components/hooks/useMediaQuery";

// SEO
import { PageSeo } from "../components/seo/seo";

// Context
import { PageTypeConsumer } from "../components/context/page-type-context";

// Components
import { DesktopTags } from "../components/index/desktop-tags";
import { TabletTags } from "../components/index/tablet-tags";
import { MobileTags } from "../components/index/mobile-tags";

const Page = styled.div`
  padding: 40px 7px;
  margin: 130px 0 0 0;

  width: 100%;

  @media (max-width: 768px) {
    margin: 75px 7px 0 7px;
  }
`;

const IndexPageContent = ({ data, setPageType, location }) => {
  const [activeTag, setActiveTag] = useState(null);
  const [activeTagName, setActiveTagName] = useState(null);
  const [activeTagProjects, setActiveTagProjects] = useState(null);
  const allProjects = data.allPrismicProject.edges;

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 960px)");
  let isTablet = useMediaQuery("(min-width: 768px) and (max-width: 959px)");
  let isMobile = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    setPageType("index");
  }, []);

  useEffect(() => {
    if (
      location !== null &&
      location !== undefined &&
      location.state !== null &&
      location.state !== undefined &&
      location.state.tag !== undefined
    ) {
      setActiveTag(location.state.tag.id);
      setActiveTagName(location.state.tag.data.title.text);
    }
  }, [location]);

  useEffect(() => {
    const findActiveTagProjects = allProjects
      .filter(project => {
        const projectTags = project.node.data.tags
          .filter(tag => tag.tag.document !== null)
          .map(tag => tag.tag.document.data.title.text);

        if (projectTags.includes(activeTagName)) {
          return project;
        } else {
          return null;
        }
      })
      .map((project, index) => (
        <div key={project.node.id}>
          <p>
            <Link
              to={`/${project.node.data.project_strand.uid}/${project.node.uid}`}
            >
              {project.node.data.title.text}
            </Link>
          </p>
        </div>
      ));

    setActiveTagProjects(findActiveTagProjects);
  }, [activeTagName, allProjects]);

  const allTagsArray = Array.from(data.allPrismicTag.edges);

  return (
    <>
      <PageSeo
        seoTitle={`Index`}
        seoText={``}
        seoImage={``}
        canonical={`/index-page/`}
      />

      <Page>
        {isDesktop && (
          <DesktopTags
            allTagsArray={allTagsArray}
            activeTag={activeTag}
            setActiveTag={setActiveTag}
            activeTagName={activeTagName}
            setActiveTagName={setActiveTagName}
            activeTagProjects={activeTagProjects}
            setActiveTagProjects={setActiveTagProjects}
          />
        )}
        {isTablet && (
          <TabletTags
            allTagsArray={allTagsArray}
            activeTag={activeTag}
            setActiveTag={setActiveTag}
            activeTagName={activeTagName}
            setActiveTagName={setActiveTagName}
            activeTagProjects={activeTagProjects}
            setActiveTagProjects={setActiveTagProjects}
          />
        )}
        {isMobile && (
          <MobileTags
            allTagsArray={allTagsArray}
            activeTag={activeTag}
            setActiveTag={setActiveTag}
            activeTagName={activeTagName}
            setActiveTagName={setActiveTagName}
            activeTagProjects={activeTagProjects}
            setActiveTagProjects={setActiveTagProjects}
          />
        )}
      </Page>
    </>
  );
};

const IndexPage = ({ data, location }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <IndexPageContent
        setPageType={setPageType}
        data={data}
        location={location}
      />
    )}
  </PageTypeConsumer>
);

export const query = graphql`
  {
    allPrismicTag(sort: { fields: data___title___html, order: ASC }) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            text {
              html
            }
            images {
              image {
                alt
                fluid {
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
    allPrismicProject(sort: { fields: data___title___html, order: ASC }) {
      edges {
        node {
          data {
            tags {
              tag {
                document {
                  ... on PrismicTag {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            title {
              text
            }
            thumbnail {
              fluid {
                srcWebp
                srcSetWebp
              }
            }
            project_strand {
              uid
            }
          }
          uid
          id
        }
      }
    }
  }
`;

export default IndexPage;
