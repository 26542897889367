import React from "react";
import styled from "styled-components";

// Utils
import { splitToChunks } from "../utils/split-to-chunks";

const ImageColumnLeft = styled.div`
  position: fixed;
  top: 100px;
  left: 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  width: 33.333%;

  @media (max-width: 959px) {
    width: 50%;
  }

  & img {
    width: 100%;
    max-width: 80%;
    max-height: 500px;

    z-index: 10;

    margin: 0 0 60px 0;

    object-fit: contain;

    @media (max-width: 959px) {
      // max-width: 50%;
      padding: 0 20px;
    }
  }
`;

const ImageColumnCenter = styled.div`
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  width: 33.333%;

  @media (max-width: 959px) {
    width: 50%;
  }

  & img {
    width: 100%;
    max-width: 80%;
    // max-height: 30vh;
    max-height: 500px;
    z-index: 10;

    margin: 0 0 60px 0;

    object-fit: contain;

    @media (max-width: 959px) {
      // max-width: 50%;
      padding: 0 20px;
    }
  }
`;

const ImageColumnRight = styled.div`
  position: fixed;
  top: 100px;
  right: 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  width: 33.333%;

  @media (max-width: 959px) {
    width: 50%;
  }

  & img {
    width: 100%;
    max-width: 80%;
    // max-height: 30vh;
    max-height: 500px;
    z-index: 10;

    margin: 0 0 60px 0;

    object-fit: contain;

    @media (max-width: 959px) {
      // max-width: 50%;
      padding: 0 20px;
    }
  }
`;

export const TagImages = ({ images, activeColumn, context }) => {
  if (context === "desktop") {
    // Split in group of 3 items
    const splitImages = splitToChunks(Array.from(images), 2);

    if (activeColumn === 1) {
      return (
        <>
          <ImageColumnCenter>{splitImages[0]}</ImageColumnCenter>

          <ImageColumnRight>{splitImages[1]}</ImageColumnRight>
        </>
      );
    }

    if (activeColumn === 2) {
      return (
        <>
          <ImageColumnLeft>{splitImages[0]}</ImageColumnLeft>

          <ImageColumnRight>{splitImages[1]}</ImageColumnRight>
        </>
      );
    }

    if (activeColumn === 3) {
      return (
        <>
          <ImageColumnLeft>{splitImages[0]}</ImageColumnLeft>

          <ImageColumnCenter>{splitImages[1]}</ImageColumnCenter>
        </>
      );
    }
  }

  if (context === "tablet") {
    return (
      <>
        {activeColumn !== 1 && <ImageColumnLeft>{images}</ImageColumnLeft>}
        {activeColumn !== 2 && <ImageColumnRight>{images}</ImageColumnRight>}
      </>
    );
  }

  if (context === "mobile") {
    return null;
  }
};
