import React, { useState } from "react";
import styled from "styled-components";

// Components
import { IndexTags } from "../index/index-tags";

// Utils
import { splitToChunks } from "../utils/split-to-chunks";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
`;

const Container = styled.ol``;

export const TabletTags = ({
  allTagsArray,
  activeTag,
  setActiveTag,
  activeTagName,
  setActiveTagName,
  activeTagProjects,
  setActiveTagProjects,
}) => {
  const [activeColumn, setActiveColumn] = useState(1);

  // Split in group of 3 items
  const splitTagsArrays = splitToChunks(allTagsArray, 2);

  const leftArray = splitTagsArrays[0].map((tag, index) => (
    <IndexTags
      tag={tag}
      index={index}
      activeTag={activeTag}
      setActiveTag={setActiveTag}
      activeTagName={activeTagName}
      setActiveTagName={setActiveTagName}
      activeTagProjects={activeTagProjects}
      setActiveColumn={setActiveColumn}
      activeColumn={1}
      context={`tablet`}
      key={`single_tablet_array_0_tag_${index}`}
    />
  ));

  const rightArray = splitTagsArrays[1].map((tag, index) => (
    <IndexTags
      tag={tag}
      index={index}
      activeTag={activeTag}
      setActiveTag={setActiveTag}
      activeTagName={activeTagName}
      setActiveTagName={setActiveTagName}
      activeTagProjects={activeTagProjects}
      setActiveColumn={setActiveColumn}
      activeColumn={2}
      context={`tablet`}
      key={`single_tablet_array_1_tag_${index}`}
    />
  ));

  return (
    <Grid>
      <Container>{leftArray}</Container>
      <Container>{rightArray}</Container>
    </Grid>
  );
};
